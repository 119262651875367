




























import { defineComponent } from '@vue/composition-api'
import { ValidationProvider, setInteractionMode, extend } from 'vee-validate'
import FormFeedback from '@/components/form/Feedback.vue'

setInteractionMode('eager')
extend('min', {
  validate(value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'form-textarea-min',
})

export default defineComponent({
  name: 'form-textarea',
  inheritAttrs: false,
  components: {
    ValidationProvider,
    FormFeedback,
  },
  props: {
    data: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: 'Message',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const value = props.data

    return {
      value,
    }
  },
})
