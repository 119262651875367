var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label h5",attrs:{"for":_vm.$attrs['name']}},[_vm._v(" "+_vm._s(_vm.data.label)+" ")]),(_vm.isMounted)?_c('VueFileAgent',{ref:"fileAgent",staticClass:"form-files",class:{ empty: _vm.fileRecords.length === 0 },attrs:{"theme":'list',"multiple":true,"deletable":true,"editable":false,"meta":true,"accept":'image/*,.pdf,.zip',"maxSize":'20MB',"maxFiles":8,"uploadUrl":"https://camber-website-v1-cms.staging.epic-sys.io/api/v1/file/upload","helpText":((_vm.data.label) + " " + (_vm.data.formats))},on:{"select":_vm.onSelect,"upload":_vm.cleanFiles,"upload:error":function($event){return _vm.onUploadError($event)}},scopedSlots:_vm._u([{key:"before-inner",fn:function(){return [_c('div',{staticClass:"form-files__info"},[_c('div',{staticClass:"form-files__info__label mb-xs"},[_c('p',[_vm._v(_vm._s(_vm.data.legend))]),_c('p',[_vm._v(" "+_vm._s(_vm.data.formats)+" ")])]),(_vm.isUploading)?_c('GLoadingDots',{staticClass:"form-files__info__loading"}):_c('g-action',{staticClass:"form-files__info__button",attrs:{"content":{
            label: _vm.data.btnLabel,
            tag: 'button',
          }}})],1)]},proxy:true},{key:"file-preview",fn:function(fileRecord){return [_c('div',{staticClass:"form-files__item__name"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"ui-file"}}),(
            fileRecord.fileRecord.raw && fileRecord.fileRecord.raw.fileKey
          )?_c('span',{staticClass:"saved"},[_vm._v(" "+_vm._s(fileRecord.fileRecord.raw.savedName)+" ")]):(fileRecord.fileRecord.raw)?_c('span',{staticClass:"new"},[_vm._v(" "+_vm._s(fileRecord.fileRecord.name())+" ")]):_vm._e()]),_c('g-action',{staticClass:"form-files__item__delete",attrs:{"content":{
          label: _vm.data.delete,
          icon: 'bin',
          tag: 'button',
          modifiers: ['reverse', 'light', 'uppercase'],
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteFile(fileRecord.fileRecord)}}})]}}],null,false,581812231),model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }